import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, delay, map, switchMap} from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import {WafCaptchaModalService} from '../../waf-captcha-modal/waf-captcha-modal.service';
import {ScriptLoaderService} from './script-loader.service';

declare const AwsWafIntegration: any;
declare const AwsWafCaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class AwsWafWrapperService {

  private static readonly retryInterval = 2000;
  private static readonly maxRetries = 10;

  constructor(private wafCaptchaModalService: WafCaptchaModalService,
              private scriptLoaderService: ScriptLoaderService) {
  }

  fetch(url: string, options?: any): Observable<any> {
    return from(AwsWafIntegration.fetch(url, options).then((response: any) => response.json()));
  }

  getAwsWafTokenHeader(): Observable<any> {
    return from(this.scriptLoaderService.loadAwsWafScript()).pipe(
      switchMap(() => this.retryWithDelay(() => this.getAwsWafToken(), AwsWafWrapperService.maxRetries)),
      catchError(error => throwError(() => new Error('Error loading AWS WAF script or getting token: ' + error)))
    );
  }

  private getAwsWafToken(): Observable<any> {
    if (typeof AwsWafIntegration !== 'undefined') {
      return from(AwsWafIntegration.getToken()).pipe(
        map(token => {
          if (token) {
            return this.buildAwsWafHeaders(token);
          } else {
            throw new Error('Empty token');
          }
        }),
        catchError(error => {
          console.error('Error getting token:', error);
          throw error;
        })
      );
    } else {
      return throwError(() => new Error('AwsWafIntegration is not defined'));
    }
  }

  private retryWithDelay<T>(operation: () => Observable<T>, retriesLeft: number): Observable<T> {
    return operation().pipe(
      catchError(error => {
        if (retriesLeft > 0) {
          console.log(`Operation failed, retrying... Retries left: ${retriesLeft - 1}`);
          return of(null).pipe(
            delay(AwsWafWrapperService.retryInterval),
            switchMap(() => this.retryWithDelay(operation, retriesLeft - 1))
          );
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  buildAwsWafHeaders(token): HttpHeaders {
    return new HttpHeaders({
      'X-Aws-Waf-Token': token
    });
  }

  captchaRequest(awsWafCaptchaKey): Promise<void> {
    return new Promise((resolve, reject) => {
      this.addCaptchaContainer();
      AwsWafCaptcha.renderCaptcha(document.querySelector("#captcha-container"), {
        apiKey: awsWafCaptchaKey,
        onSuccess: () => {
          this.hideCaptchaContainer();
          resolve();
        },
        onError: (error: any) => reject(error),
        skipTitle: true
      });
    });
  }

  addCaptchaContainer() {
    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', 'captcha-container');
    this.wafCaptchaModalService.open(newDiv);
  }

  hideCaptchaContainer() {
    this.wafCaptchaModalService.close();
    const captchaContainer = document.getElementById('captcha-container');
    if (captchaContainer) {
      captchaContainer.style.display = 'none';
    }
  }
}
